import { UIHelper } from '@mpx-sdk/helpers/ui';
import { GradientTypography } from '@mpx-sdk/ui/components';
import { Box, Link, Stack, Typography } from '@mui/material';

function Slide4() {
	const isMobile = UIHelper.isMobile();
	return (
		<Box>
			<Stack style={{ textAlign: 'left' }}>
				<GradientTypography textAlign='left' variant={isMobile ? 'h6' : 'h4'}>
					Time to Start Generating!
				</GradientTypography>
			</Stack>
			<Stack
				sx={{
					backgroundColor: isMobile ? '#303030' : '#444444',
					width: isMobile ? '100%' : '55%',
					padding: isMobile ? '10px' : '15px',
					borderRadius: '20px',
					mt: isMobile ? '100px' : '20px',
					mb: isMobile ? '20px' : 'none',
				}}
			>
				<Stack spacing={2} sx={{ padding: '20px' }}>
					<Typography variant='body1'>Time to dive into 3D model generation!</Typography>

					<Typography variant='body1'>
						We can&apos;t wait to see the amazing creations you come up with. If you have any questions or
						feedback, please don&apos;t hesitate to &nbsp;
						<Link
							color='primary'
							fontWeight='bold'
							href='https://www.masterpiecex.com/contact'
							style={{ textDecoration: 'none' }}
							target='_blank'
						>
							reach out to us.
						</Link>
					</Typography>

					<Typography variant='body1'>
						We&apos;re dedicated to enhancing our AI daily to provide you with high-quality and usable
						models.
					</Typography>
				</Stack>
			</Stack>
		</Box>
	);
}

export default Slide4;
