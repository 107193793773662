import ImageInfo from '@genai/components/ImageInfo';
import Filter from '@genai/components/right-sidebar/Filter';
import HistoryCard from '@genai/components/right-sidebar/HistoryCard';
import QueueDisplay from '@genai/components/right-sidebar/QueueDisplay';
import Sorting from '@genai/components/right-sidebar/Sorting';
import { matchBucketToPostgresDataFormat } from '@mpx-sdk/helpers/assets';
import Auth0Helper, { clearAccessCookies } from '@mpx-sdk/helpers/auth';
import { singleAssetViewAtom, userAtom, userHistoryAtom } from '@mpx-sdk/shared/atoms';
import { MLTypes, databases } from '@mpx-sdk/shared/configs';
import { GridView as GridViewIcon, Splitscreen as SplitscreenIcon } from '@mui/icons-material';
import { Box, Button, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { collection, getFirestore, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { ReactElement, useEffect, useState } from 'react';

function customFilter(data: any[], currentFilter: string | string[]): any[] {
	return data.filter((item) => {
		if (currentFilter.includes('all') || currentFilter.length === 0) {
			return true; // Include all items if 'all' is present in the filter
		}

		const {
			status,
			downloaded,
			data: { meshType },
		} = item;

		if (currentFilter.includes('complete') && status === 'complete') {
			return true; // Include if 'complete' or 'processing' is present in the filter and item status matches
		}

		if (
			currentFilter.includes('processing') &&
			(status === 'processing' || status === 'pending' || status === 'dispatching')
		) {
			return true; // Include if 'complete' or 'processing' is present in the filter and item status matches
		}

		if (currentFilter.includes('humans') && meshType === 'human') {
			return true; // Include if 'human' is present in the filter and item meshType is 'human'
		}

		if (currentFilter.includes('animals') && meshType === 'animal') {
			return true; // Include if 'animal' is present in the filter and item meshType is 'animal'
		}

		if (currentFilter.includes('objects') && meshType === 'object') {
			return true; // Include if 'object' is present in the filter and item meshType is 'object'
		}

		if (currentFilter.includes('downloads') && downloaded !== undefined) {
			return true; // Include if 'object' is present in the filter and item meshType is 'object'
		}

		return false; // Exclude the item if none of the filter conditions are met
	});
}

export default function RightSidebar(): ReactElement {
	const [currentFilter, setCurrentFilter] = useState(['all']);
	const [currentSorting, setCurrentSorting] = useState('Newest');
	const [gridView, setGridView] = useState(false);
	const [history, setHistory] = useAtom(userHistoryAtom);
	const currentUser = useAtomValue(userAtom);
	const setProjectData = useSetAtom(singleAssetViewAtom);
	const { auth0Id: userId } = (currentUser as any) || {};

	useEffect(() => {
		// Currently there might be a mismatch between userId received  from api and firebase due to cross project settings in prototyping, so using firebase id instead
		if (userId)
			onSnapshot(
				query(
					collection(getFirestore(), databases.mlRequest),
					where('userId', '==', userId),
					where('type', 'in', [
						MLTypes.MESH_TO_ANIMATION,
						MLTypes.MESH_TO_ANIMATION_ANIMAL,
						MLTypes.MESH_TO_ANIMATION_OBJECT,
						MLTypes.MESH_TO_ANIMATION_AUTO,
						MLTypes.MESH_TO_ANIMATION_ANIMAL_AUTO,
						MLTypes.MESH_TO_ANIMATION_OBJECT_AUTO,
						MLTypes.MESH_TO_ANIMATION_BASE_GEN,
						MLTypes.MESH_TO_ANIMATION_ANIMAL_BASE_GEN,
						MLTypes.MESH_TO_ANIMATION_OBJECT_BASE_GEN,
					]),
					orderBy('createdAt', currentSorting === 'Oldest' ? 'asc' : 'desc'),
				),
				(snapshot) => {
					const data = snapshot.docs?.map((doc) => {
						const data = doc.data();

						return { ...data, id: doc.id };
					});

					setHistory(data || []);
				},
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser]);

	return (
		<Stack
			spacing={2}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				maxWidth: '100vw',
				scrollBehavior: 'smooth',
				overflow: 'hidden',
				bgcolor: 'background.dark',
				flex: 1,
				p: 2,
				width: '100%',
				position: 'relative',
			}}
		>
			{/* <ModelHistoryGrpBtn /> */}

			<Box
				sx={{
					flex: '1 1 auto !important',
				}}
			>
				<Typography
					fontWeight='bolder'
					sx={{
						mb: 1,
					}}
				>
					History
				</Typography>

				{history.length > 0 ? (
					<>
						<Stack
							direction='row'
							justifyContent='space-between'
							sx={{
								paddingRight: '0.5rem',
								maxWidth: '100%',
							}}
						>
							<Filter
								onFilterChange={(filterBy) => {
									setCurrentFilter(filterBy);
								}}
							/>

							<Stack alignItems='center' direction='row' justifyContent='center' spacing={1}>
								<Sorting onSortingChange={setCurrentSorting} />

								<Box>
									<IconButton
										onClick={() => {
											setGridView(!gridView);
										}}
										sx={{
											alignItems: 'center',
											border: (theme) => `1px solid ${theme.palette.background.info}`,
											borderRadius: '20px',
											cursor: 'pointer',
											display: 'flex',
											mb: 1,
											minHeight: '40px !important',
											py: 0.3,
										}}
									>
										{gridView ? <SplitscreenIcon /> : <GridViewIcon />}
									</IconButton>
								</Box>
							</Stack>
						</Stack>

						<Grid
							alignItems='flex-start'
							container
							direction={!gridView ? 'column' : 'row'}
							justifyContent='flex-start'
							spacing={1}
							sx={{
								paddingTop: '1rem',
								paddingRight: '0.5rem',
							}}
						>
							{customFilter(
								history.sort(
									(
										a: {
											downloaded: boolean;
											projectTitle: any;
											id: any;
											createdAt: number;
										},
										b: {
											downloaded?: boolean;
											projectTitle: any;
											id: any;
											createdAt: number;
										},
									) => {
										// Sort by downloaded status when 'Downloads' option is selected in the filter
										if (currentFilter.includes('downloads') && (a.downloaded || b.downloaded)) {
											return 0;
										}

										const titleA = (a?.projectTitle || a.id || '').toLowerCase();
										const titleB = (b?.projectTitle || b.id || '').toLowerCase();

										if (currentSorting === 'Oldest') {
											return a.createdAt - b.createdAt;
										}
										if (currentSorting === 'Alphabetical (A-Z)') {
											return titleA.localeCompare(titleB);
										}
										if (currentSorting === 'Alphabetical (Z-A)') {
											return titleB.localeCompare(titleA);
										}

										return b.createdAt - a.createdAt;
									},
								),
								currentFilter,
							).map((item) => (
								<HistoryCard
									key={item.id}
									data={item}
									downloaded={item.downloaded !== undefined}
									gridView={gridView}
									onClick={() => {
										setProjectData(matchBucketToPostgresDataFormat(item));
									}}
									thumbnailUrl={
										item?.steps?.find(
											(step: { stage: string; status: string }) =>
												step.stage === 'render' && step.status === 'complete',
										)?.outputFile
									}
									title={item?.projectTitle || item?.id}
								/>
							))}
						</Grid>
					</>
				) : (
					<ImageInfo imageUrl='/RemixHistoryImage.png'>
						{currentUser ? (
							<>
								Your AI Remix history will appear here. <br />
								Results will disappear after 7 days.
							</>
						) : (
							<Button
								onClick={(e) => {
									e.preventDefault();

									clearAccessCookies();
									Auth0Helper.login();
								}}
								variant='text'
							>
								Login to view your AI Remix history.
							</Button>
						)}
					</ImageInfo>
				)}
			</Box>

			<Box
				sx={{
					position: 'sticky',
				}}
			>
				<Divider />

				<QueueDisplay history={history} />
			</Box>
		</Stack>
	);
}
