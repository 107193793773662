import Gallery from '@genai/components/gallery/Gallery';
import LeftSidebar from '@genai/components/left-sidebar/LeftSidebar';
import RightSidebar from '@genai/components/right-sidebar/RightSideBar';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { genAiResponsiveViewAtom, userAtom } from '@mpx-sdk/shared/atoms';
import { Grid } from '@mui/material';
import { useAtomValue } from 'jotai';
import { debounce } from 'lodash';
import { useEffect } from 'react';

export default function GenAI() {
	const responsiveView = UIHelper.isResponsiveView();
	const genAiResponsiveView = useAtomValue(genAiResponsiveViewAtom);
	const currentUser = useAtomValue(userAtom);

	const displayOptions = [
		{
			jsx: <LeftSidebar />,
			id: 'create-display',
			size: {
				md: 3.5,
				sm: 12,
				xl: 3,
			},
		},
		{
			jsx: <Gallery />,
			id: 'gallery-display',
			size: {
				md: 5,
				sm: 12,
				xl: 6,
			},
		},
		{
			jsx: <RightSidebar />,
			id: 'history-display',
			size: {
				md: 3.5,
				sm: 12,
				xl: 3,
			},
		},
	];

	const checkOnboarding = debounce(() => {
		const hasSeenWelcome = localStorage?.getItem('genAIOnboarding');

		if (!hasSeenWelcome && currentUser?.createdAt) {
			const today = new Date();
			const createdAtDate = new Date(currentUser.createdAt);

			const isToday =
				createdAtDate.getFullYear() === today.getFullYear() &&
				createdAtDate.getMonth() === today.getMonth() &&
				createdAtDate.getDate() === today.getDate();

			if (isToday && document?.getElementById('genai-onboarding-button')) {
				console.log('clicking');
				document.getElementById('genai-onboarding-button')?.click();

				if (localStorage) {
					localStorage?.setItem('genAIOnboarding', 'true');
				}
			}
		}
	}, 1000);

	useEffect(() => {
		checkOnboarding();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid
			columnSpacing={{ xs: 1, sm: 2, md: 0 }}
			container
			height='100%'
			rowSpacing={1}
			sx={{
				margin: 'auto',
				'& > .MuiGrid-item > :first-of-type': {
					height: '100%',
					overflowY: 'auto',
					overflowX: 'hidden',
				},
			}}
		>
			{displayOptions.map(({ jsx, id, size }) => (
				<Grid
					key={id}
					data-category='display'
					data-id={id}
					item
					md={size.md}
					sm={size.sm}
					sx={{
						border: (theme: any) =>
							responsiveView ? `1px solid ${theme.palette.background.offBlack}` : 'initial',
						display: !responsiveView && genAiResponsiveView !== id ? 'none' : 'block',
						height: '100%',
						margin: 'auto',
						paddingTop: '0 !important',
						width: '100%',
					}}
					xl={size.xl}
				>
					{jsx}
				</Grid>
			))}
		</Grid>
	);
}
