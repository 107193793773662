/* eslint-disable jsx-a11y/anchor-is-valid */
import Link, { LinkProps } from '@mui/material/Link';

export default function ButtonLink({ children, ...props }: LinkProps) {
	return (
		<Link component='button' type='button' {...props}>
			{children}
		</Link>
	);
}
