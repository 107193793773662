import ChipsList from '@genai/components/left-sidebar/TooltipChips';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import FormField from '@mpx-sdk/ui/components/core/form/FormField';
import IconTooltip from '@mpx-sdk/ui/components/tooltips/IconTooltip';
import { Box, Card, CardActionArea, CardMedia, Collapse, Grid, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Field, useForm } from 'react-final-form';

export default function AnimateStep(): ReactElement {
	const form = useForm();
	const responsiveView = UIHelper.isResponsiveView();

	const handleAutofillClick = (text: string) => {
		form.change('movesLikePrompt', `${text}`);
	};

	const getMeshType = () => {
		const meshType = form.getFieldState('meshType')?.value;
		if (meshType === 'human') {
			return 'human';
		}
		if (meshType === 'animal') {
			return 'animal';
		}
		return 'object';
	};

	const autofillOptions: Record<string, string[]> = {
		human: ['Waving', 'Standing', 'Running', 'Walking', 'Dancing', 'Clapping', 'Crouching Down', 'Jumping'],
	};

	const modelTypeOptions = [
		{ label: 'Bones Only', value: 'rig_only', image: '/Rig_Only.png' },
		{ label: 'Bones & Animation', value: 'rig_animation', image: '/Rig_And_Bones.png' },
		{ label: 'None ', value: 'none', image: '/No_Rig.png' },
	];

	return (
		<Stack direction='column' spacing={1}>
			<Typography variant='h6'>Animation Details</Typography>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Typography>How does your human move?</Typography>

				{/* Add the IconTooltip component here */}
				<IconTooltip
					paragraphs={[
						<>
							<b>Select your preference for your human model generation:</b> <br />
							<ul>
								{' '}
								<li>
									{' '}
									&quot;Rig Only&quot; enables you to animate your human model within other
									applications.
								</li>{' '}
								<li>
									{' '}
									&quot;Rig and Animation&quot; will create your model with a rig and integrated
									animation.{' '}
								</li>{' '}
								<li>None</li>
							</ul>
						</>,
					]}
					title='Animation Details'
				/>
			</Box>

			<Field
				name='animationType'
				render={({ input }) => (
					<Grid
						alignItems='stretch'
						container
						sx={{
							width: '100%',
							'& .MuiGrid-item': {
								paddingLeft: 'min(6px, 1%)',
								paddingRight: 'min(6px, 1%)',
							},
						}}
					>
						{modelTypeOptions.map(({ label, value, image }) => (
							<Grid
								key={label}
								item
								onClick={() => input.onChange(value)}
								sx={{
									cursor: 'pointer',
								}}
								xs={4}
							>
								<Box height='100%' textAlign='center'>
									<Card
										component={Stack}
										sx={{
											boxShadow: (theme) =>
												input.value === value
													? `0 0 0 2px ${theme.palette.primary.main}`
													: 'none',
										}}
									>
										<CardActionArea>
											<CardMedia
												alt='Card Image'
												component='img'
												image={image}
												sx={{
													height: '8rem',
													padding: 1,
													maxWidth: '100%',
													objectFit: 'contain',
													margin: 'auto',
												}}
											/>
											<Typography fontSize='0.9rem' height='3rem' textAlign='center'>
												{label}
											</Typography>
										</CardActionArea>
									</Card>
								</Box>
							</Grid>
						))}
					</Grid>
				)}
			/>
			<Stack spacing={2}>
				<Typography color='error' hidden={form.getFieldState('meshType')?.value === 'human'}>
					<b>Animation is currently only available for human models.</b>
				</Typography>
				<Collapse in={form.getFieldState('animationType')?.value === 'rig_animation'}>
					<Stack pt={1} spacing={1}>
						<FormField
							disabled={form.getFieldState('meshType')?.value !== 'human'}
							fullWidth
							interactive
							multiline
							name='movesLikePrompt'
							placeholder='Simple actions work best. Like waving, running, etc...'
							rows={4}
							title={`How does your ${form.getFieldState('meshType')?.value} move? (Required)`}
							tooltip={
								responsiveView
									? {
											title: 'Animation Details',
											content: [
												'This is where we “rig” your model to make it move',

												<ChipsList
													key='chipslist4'
													chips={autofillOptions[getMeshType()]}
													onClick={(option) => handleAutofillClick(option)}
												/>,
											],
									  }
									: undefined
							}
							variant='outlined'
						/>

						{!responsiveView && (
							<ChipsList
								key='chipslist4'
								chips={autofillOptions[getMeshType()]}
								onClick={(option) => handleAutofillClick(option)}
							/>
						)}
					</Stack>
				</Collapse>
			</Stack>
		</Stack>
	);
}
