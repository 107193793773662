import { UIHelper } from '@mpx-sdk/helpers/ui';
import { singleAssetViewAtom } from '@mpx-sdk/shared/atoms';
import { PublicAsset } from '@mpx-sdk/types';
import AssetThumbnail from '@mpx-sdk/ui/components/assets/card/AssetThumbnail';
import { Box, Grid, Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import { isEmpty } from 'lodash';
import { ReactElement } from 'react';

interface GalleryCardsProps {
	/** List of assets/projects to display */
	projectData?: PublicAsset[];

	/** The function to call when a project is clicked */
	onClick?: (...args: any[]) => void;
}

export default function GalleryCards({ projectData, onClick }: GalleryCardsProps): ReactElement | null {
	const responsiveView = UIHelper.isResponsiveView();
	const setProjectData = useSetAtom(singleAssetViewAtom);

	return !isEmpty(projectData) ? (
		<Box
			data-testid='genai-algolia-gallery'
			id='genai-algolia-gallery'
			sx={{
				height: '100%',
				maxWidth: '100%',
				padding: '0 1rem',
			}}
		>
			<Grid
				container
				spacing={1}
				sx={{
					margin: '2% auto !important',
					maxWidth: '100%',
					overflow: 'hidden',
					padding: '0 !important',
				}}
			>
				{projectData?.map((hit) => {
					if (!hit.thumbnailUrl || hit.thumbnailUrl === 'undefined/static/no-image-placeholder.jpg') {
						return null;
					}

					return (
						<Grid
							key={hit.objectID}
							item
							onClick={() => {
								setProjectData(hit);

								onClick?.(hit);
							}}
							sx={{
								maxWidth: '100%',
								padding: '0.5rem !important',
								'&:hover': {
									'.gallery-description': {
										display: '-webkit-box',
									},
								},
								img: {
									borderRadius: '12px',
									objectFit: 'cover',
									width: '100% !important',
								},
							}}
							xl={4}
							xs={6}
						>
							<Box
								sx={{
									backgroundColor: 'background.assets',
									borderRadius: '12px',
									cursor: 'pointer',
									minHeight: !responsiveView ? '45vw' : '17vw',
									position: 'relative',
									width: '100%',
								}}
							>
								<AssetThumbnail alt={hit.title} src={hit.thumbnailUrl} />

								{/* Add description as text that appears on top of (but at bottom) of image */}
								<Typography
									className='gallery-description'
									sx={{
										// '-webkit-box-orient': 'vertical',
										// '-webkit-line-clamp': '2',
										backdropFilter: 'blur(4px)',
										background: 'rgba(217, 217, 217, 0.45)',
										borderRadius: '0 0 12px 12px',
										bottom: 0,
										color: 'black',
										display: !responsiveView ? 'block' : 'none',
										fontSize: '1rem',
										fontStyle: 'normal',
										fontWeight: '500',
										leadingTrim: 'both',
										left: 0,
										letterSpacing: '-0.24px',
										lineClamp: '2',
										lineHeight: '22px',
										maxHeight: 'min(59px, calc(3.6rem + 37px))',
										overflow: 'hidden',
										padding: '0.8rem 16px 8px',
										position: 'absolute',
										right: 0,
										textAlign: 'left',
										textEdge: 'cap',
										textOverflow: 'ellipsis',
										whiteSpace: 'normal',
									}}
									variant='body1'
								>
									{hit?.metadata?.genAIData?.['Paint Looks Like'] ||
										hit?.metadata?.genAIData?.Shape ||
										hit?.description ||
										hit?.title ||
										'Community created content'}
								</Typography>
							</Box>
						</Grid>
					);
				})}
			</Grid>
		</Box>
	) : null;
}
