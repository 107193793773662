import { activeStepAtom, sessionAtom } from '@genai/atoms';
import ProjectTitle from '@genai/components/left-sidebar/ProjectTitle';
import AnimateStep from '@genai/components/left-sidebar/steps/AnimateStep';
import PaintStep from '@genai/components/left-sidebar/steps/PaintStep';
import ResultsStep from '@genai/components/left-sidebar/steps/ResultsStep';
import ShapeStep from '@genai/components/left-sidebar/steps/ShapeStep';
import StartStep from '@genai/components/left-sidebar/steps/StartStep';
import { OnboardingDialog } from '@genai/components/onboarding';
import MLProcessingDoc from '@genai/models/MLProcessingDoc';
import MLSession from '@genai/models/MLSession';
import StepNames from '@genai/models/StepNames';
import Auth0Helper, { clearAccessCookies } from '@mpx-sdk/helpers/auth';
import { getBalanceByCurrency, getMyBalance } from '@mpx-sdk/helpers/economy';
import { isWordMature } from '@mpx-sdk/helpers/input';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { CreditsIcon } from '@mpx-sdk/images';
import { availableCreditsAtom, mainForm, userAtom, userHistoryAtom } from '@mpx-sdk/shared/atoms';
import { MLTypes } from '@mpx-sdk/shared/configs';
import { MLSnapshotData } from '@mpx-sdk/types';
import { useDialogService } from '@mpx-sdk/ui/components/core/DialogService';
import BuyMoreCredit from '@mpx-sdk/ui/components/purchase/BuyMoreCredit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, IconButton, Slide, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import { onSnapshot } from 'firebase/firestore';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { find, findIndex, isEmpty, mean } from 'lodash';
import { ReactElement, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { toast } from 'react-toastify';

export const steps = [
	{ name: StepNames.START, component: StartStep, order: 0, buttonText: 'Next Step: Add Shape Details' },
	{ name: StepNames.SHAPE, component: ShapeStep, order: 1, buttonText: 'Next Step: Add Paint' },
	{ name: StepNames.PAINT, component: PaintStep, order: 2, buttonText: 'Next Step: Add Animation' },
	{ name: StepNames.ANIMATE, component: AnimateStep, order: 3, buttonText: 'Generate 3D Model(s) ' },
	{ name: StepNames.RESULTS, component: ResultsStep, order: 4 },
];

export const getMeshTypeByVariability = (
	modelGenerationIndex: number,
	variability: number,
	meshType: 'animal' | 'object' | 'human',
) => {
	if (meshType === 'object') {
		meshType = 'animal';
	}

	const data = [
		{ type: 'animal', variability: 0, autoSelect: [1, 2, 3, 4], meshGen: [] },
		{ type: 'animal', variability: 1, autoSelect: [1, 2, 3, 4], meshGen: [] },
		{ type: 'animal', variability: 2, autoSelect: [1, 3], meshGen: [2, 4] },
		{ type: 'animal', variability: 3, autoSelect: [2, 4], meshGen: [1, 3] },
		{ type: 'animal', variability: 4, autoSelect: [], meshGen: [1, 2, 3, 4] },
		{ type: 'human', variability: 0, autoSelect: [1, 2, 3, 4], meshGen: [] },
		{ type: 'human', variability: 1, autoSelect: [1, 2, 3, 4], meshGen: [] },
		{ type: 'human', variability: 2, autoSelect: [1, 2, 3, 4], meshGen: [] },
		{ type: 'human', variability: 3, autoSelect: [1, 3], meshGen: [2, 4] },
		{ type: 'human', variability: 4, autoSelect: [], meshGen: [1, 2, 3, 4] },
	];

	const dataForVariability = data.filter((d) => d.variability === variability);
	const dataForMeshType = dataForVariability.filter((d) => d.type === meshType);

	if (find(dataForMeshType[0].autoSelect, (a) => a === modelGenerationIndex)) return 'autoSelect';

	if (find(dataForMeshType[0].meshGen, (a) => a === modelGenerationIndex)) return 'generateMesh';

	return null;
};

export default function LeftSidebar(): ReactElement {
	const [activeStep, setActiveStep] = useAtom(activeStepAtom);
	const [processingDocs, setProcessingDocs] = useState<[]>([]);

	const [enableResultsStep, setEnableResultsStep] = useState(false);
	const availableCredits = useAtomValue(availableCreditsAtom);
	const containerRef = useRef(null);
	const currentUser = useAtomValue(userAtom);
	const isAnimateStep = activeStep === StepNames.ANIMATE;
	const isPaintStep = activeStep === StepNames.PAINT;
	const setForm = useSetAtom(mainForm);
	const setSession = useSetAtom(sessionAtom);
	const userHistory = useAtomValue(userHistoryAtom);
	const { showDialog } = useDialogService();

	const handleNext = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		const currentStep = findIndex(steps, { name: activeStep });
		if (currentStep < steps.length - 1) {
			setActiveStep(steps[currentStep + 1].name);
		}
	};

	const handleSubmit = async ({
		projectTitle,
		skipAnimate,
		skipPaint,
		modelsToGenerate,
		meshVariability,
		animationType,
		...submission
	}: any) => {
		if (!currentUser) {
			toast.error('Please login to generate models.');

			return null;
		}

		delete submission.source;

		setSession(new MLSession());

		// Check if any of looksLikePrompt, movesLikePrompt, promptNeg and promptPos contain any mature words
		const isMature = {
			shape: isWordMature(projectTitle),
			'looks like': isWordMature(submission.looksLikePrompt),
			'moves like': isWordMature(submission.movesLikePrompt),
			'paint does not look like': isWordMature(submission.promptNeg),
			'paint look like': isWordMature(submission.promptPos),
		};
		// Check if any of the isMature values are true and if so, show error message of where the mature word is located and return null
		if (Object.values(isMature).some((value) => value)) {
			const matureWords = Object.keys(isMature).filter((key) => isMature[key]);
			toast.error(`Please remove any mature words from your ${matureWords.join(', ')} prompts.`);

			return null;
		}

		const promptPos = submission.promptPos ? `, ${submission.promptPos}` : '';

		try {
			const documents = await Promise.all(
				Array(modelsToGenerate)
					.fill('')
					.map((_val, index) =>
						new MLProcessingDoc({
							data: {
								animate: submission.meshType === 'human' && animationType !== 'none',
								rig_only: animationType === 'rig_only',
								paint: !skipPaint,
								...submission,
								category: `${submission.looksLikePrompt}`,
								looksLikePrompt: `${submission.looksLikePrompt}`,
								promptPos: `${submission.looksLikePrompt}${promptPos}`,
								meshVariability,
								meshSelection: getMeshTypeByVariability(
									index + 1,
									meshVariability,
									submission.meshType,
								),
							},
							projectTitle: `${submission?.looksLikePrompt?.replace(/ /g, '_')}_${index + 1}`,
							type: MLTypes.MESH_TO_ANIMATION,
						}).save(),
					),
			);

			await getMyBalance();

			// Check if user has enough credits to generate models
			// const generationCredits = getBalanceByCurrency('credits', availableCredits);
			// if (!generationCredits || generationCredits < modelsToGenerate) {
			// 	throw new Error('Not enough credits');
			// }
			toast.success(
				'Generating your model(s) now... \n This may take 2-8 minutes for each model. You can see the models in history panel',
			);
			documents.forEach((document: any) => {
				const unsubscribe = onSnapshot(document.ref, (doc: any) => {
					const data: any = { ...(doc.data() as MLSnapshotData), id: doc.id };

					data.unsubscribe = unsubscribe;

					setProcessingDocs((prev: any) => {
						const newState: any = [...prev];

						const index = findIndex(newState, {
							id: doc.id,
						});

						if (index !== -1) newState[index] = data;
						else newState.push(data);

						return newState;
					});

					if (data.status === 'complete') {
						unsubscribe();
					}
				});
			});

			setEnableResultsStep(true);
			setActiveStep(StepNames.RESULTS);

			DataLayer.triggerMeasurementEvent('generateEvent', {
				event_name: 'generate_model',
				user_id: currentUser?.id,
				user_number_of_created_models: userHistory?.length ?? 0,
				success: true,
				mesh_variability: meshVariability,
				models_to_generate: modelsToGenerate,
				project_title: projectTitle,
				skip_animate: skipAnimate,
				skip_paint: skipPaint,
				available_credits: getBalanceByCurrency('credits', availableCredits),
				...submission,
			});
		} catch (err) {
			if (isAxiosError(err) && err?.response?.status === 402) {
				showDialog(BuyMoreCredit.getAsDialog());
			} else {
				console.error('Error while generating', err);
				toast.error('There was an error generating your model(s). If this persists, please contact support.');
			}

			DataLayer.triggerMeasurementEvent('generateEvent', {
				event_name: 'generate_model',
				user_id: currentUser?.id,
				user_number_of_created_models: userHistory?.length ?? 0,
				success: false,
				mesh_variability: meshVariability,
				models_to_generate: modelsToGenerate,
				project_title: projectTitle,
				skip_animate: skipAnimate,
				skip_paint: skipPaint,
				...submission,
				available_credits: getBalanceByCurrency('credits', availableCredits),
			});
		}
	};

	return (
		<Box
			bgcolor='background.dark'
			position='relative'
			sx={{
				display: 'flex',
			}}
			width='100%'
		>
			<Box ref={containerRef} width='100%'>
				<Form
					initialValues={{
						animationType: 'none',
						meshSelection: 'autoGenerate',
						meshType: 'object',
						meshVariability: 2,
						modelsToGenerate: 2,
						projectTitle: 'Untitled Model Project 1',
						sigma: 1.5,
						source: 'library',
					}}
					onSubmit={handleSubmit}
				>
					{({ handleSubmit, form, submitting }) => {
						setForm(form);

						return (
							<Stack
								component='form'
								onSubmit={
									(form.getFieldState('meshType')?.value === 'human' ? !isAnimateStep : !isPaintStep)
										? handleNext
										: handleSubmit
								}
								spacing={1}
								width='100%'
							>
								<Box display='none'>
									<ProjectTitle />
								</Box>

								<OnboardingDialog />

								<Stepper
									activeStep={findIndex(steps, { name: activeStep })}
									alternativeLabel
									sx={{
										position: 'relative',
										transition: 'all 0.5s ease-in-out',
										pt: 1,
										'.MuiStepLabel-label': {
											transition: 'all 0.5s ease-in-out',
											'.MuiTypography-root': {
												fontSize: 'clamp(0.5rem, 0.7rem, 1rem)',
											},
											marginTop: '5%',
										},
										'.Mui-completed': {
											transition: 'all 0.5s ease-in-out',
											color: '#303030 !important',
											'.MuiStepConnector-line': {
												borderColor: '#303030 !important',
											},
										},
										'.Mui-active': {
											transition: 'all 0.5s ease-in-out',
											color: '#00E7AF !important',
											'.MuiStepConnector-line': {
												borderColor: '#00E7AF !important',
											},
										},
										'.MuiStepIcon-root': {
											fontSize: '1.75rem !important',
										},
										'.MuiStepConnector-root': {
											'.MuiStepConnector-line': {
												height: 3,
												border: 0,
												borderRadius: 1,
												transition: 'all 0.5s ease-in-out',
												borderTopWidth: '3px !important',
											},
										},
									}}
								>
									{steps.map(({ name: label }) => {
										const isSelectedHuman = form.getFieldState('meshType')?.value === 'human';

										if (label === StepNames.ANIMATE && !isSelectedHuman) {
											return null;
										}

										const isStepActive = label === activeStep;

										return (
											<Step key={`step-${label}`}>
												<StepLabel
													onClick={() => {
														if (label === StepNames.RESULTS && !enableResultsStep) {
															return;
														}

														if (
															![StepNames.START, StepNames.SHAPE].includes(label) &&
															isEmpty(form.getFieldState('looksLikePrompt')?.value)
														) {
															setActiveStep(StepNames.SHAPE);
														} else {
															setActiveStep(label);
														}
													}}
													sx={{
														zIndex: 1,
														display: 'flex',
														borderRadius: '50%',
														justifyContent: 'center',
														alignItems: 'center',
														textTransform: isStepActive ? 'uppercase' : 'capitalize',
														'& .MuiStepLabel-label': {
															color: isStepActive ? '#00E7AF' : '#BEBEBE',
														},
													}}
												>
													<Typography variant='caption'>{label}</Typography>
												</StepLabel>
											</Step>
										);
									})}
								</Stepper>

								<Slide
									key={`step-slide-${activeStep}`}
									container={containerRef.current}
									direction='left'
									in
								>
									<Box>
										{steps.map(({ component: StepComponent, name, buttonText }) => {
											if (
												name === StepNames.PAINT &&
												form?.getFieldState('meshType')?.value !== 'human'
											) {
												buttonText = 'Generate 3D Model(s)';
											}

											return (
												<Stack
													key={`step-${name}`}
													display={name === activeStep ? 'flex' : 'none'}
													px={2}
													spacing={3}
													sx={{ pt: 2, p: '12px' }}
												>
													<StepComponent
														progress={
															mean(
																processingDocs.map((doc: any) =>
																	Number(doc?.progress || 0),
																),
															) || 0
														}
													/>

													{/* Show Credits Cost */}
													{activeStep !== StepNames.RESULTS && (
														<Stack direction='column' spacing={1}>
															<Divider />
															<Stack
																alignItems='center'
																direction='row'
																justifyContent='space-between'
																sx={{
																	pt: 2,
																}}
															>
																<span>Credit cost:</span>
																<Stack alignItems='center' direction='row' spacing={1}>
																	<CreditsIcon />
																	<span>
																		{50 *
																			(form?.getFieldState?.('modelsToGenerate')
																				?.value ?? 1)}{' '}
																		credits
																	</span>
																</Stack>
															</Stack>

															<Stack
																alignItems='center'
																direction='row'
																justifyContent='space-between'
																sx={{
																	pt: 2,
																}}
															>
																<Typography>Estimated time:</Typography>

																<Typography>2-8 min</Typography>
															</Stack>
														</Stack>
													)}

													{/* CTA */}
													<Stack alignItems='center' direction='row' justifyContent='center'>
														{activeStep !== StepNames.START &&
															activeStep !== StepNames.RESULTS && (
																<IconButton
																	disableRipple
																	onClick={() => {
																		const currentStepIndex = findIndex(steps, {
																			name: activeStep,
																		});
																		if (currentStepIndex > 0) {
																			setActiveStep(
																				steps[currentStepIndex - 1].name,
																			);
																		}
																	}}
																>
																	<ArrowBackIosIcon
																		sx={{
																			color: '#00E7AF',
																		}}
																	/>
																	<Typography color='primary'>Back</Typography>
																</IconButton>
															)}

														{buttonText && (
															<LoadingButton
																disabled={
																	activeStep === StepNames.SHAPE &&
																	isEmpty(
																		form.getFieldState('looksLikePrompt')?.value,
																	)
																}
																loading={submitting}
																onClick={(e) => {
																	if (!currentUser) {
																		e.preventDefault();

																		clearAccessCookies();
																		Auth0Helper.login();
																	}
																}}
																sx={{
																	background: (theme) =>
																		activeStep === StepNames.SHAPE &&
																		isEmpty(
																			form.getFieldState('looksLikePrompt')
																				?.value,
																		)
																			? theme.palette.primary.dark
																			: theme.palette.gradient.main,
																	backgroundColor: 'original.main',
																	borderRadius: '100px',
																	fontSize: 'clamp(0.5rem, 1rem, 1rem)',
																	fontWeight: '700',
																	letterSpacing: '0.0025em',
																	lineHeight: '32px',
																	width: '100%',
																	'&:hover': {
																		background: (theme) =>
																			theme.palette.gradient.hover,
																	},
																}}
																type='submit'
																variant='contained'
															>
																{currentUser && buttonText.includes('Generate') && (
																	// eslint-disable-next-line @next/next/no-img-element
																	<img
																		alt='wand_icon'
																		height='20px'
																		src='/magic_wand.svg'
																		style={{
																			marginRight: '1%',
																		}}
																		width='20px'
																	/>
																)}

																{currentUser ? buttonText : 'Login to Generate'}
															</LoadingButton>
														)}
													</Stack>
												</Stack>
											);
										})}
										<br />
									</Box>
								</Slide>
							</Stack>
						);
					}}
				</Form>
			</Box>
		</Box>
	);
}
