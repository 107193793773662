/* eslint-disable consistent-return, no-nested-ternary */
import { getMyBalance } from '@mpx-sdk/helpers/economy';
import { defaultThumbnail } from '@mpx-sdk/images';
import { inAppBrowserAtom, queueStatus, userHistoryAtom } from '@mpx-sdk/shared/atoms';
import { urls } from '@mpx-sdk/shared/configs';
import { LinkWrapper } from '@mpx-sdk/ui/components';
import { DownloadDone, Error } from '@mui/icons-material';
import {
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	CircularProgress,
	Grid,
	LinearProgress,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { usePreviousProps } from '@mui/utils';
import { useAtomValue } from 'jotai';
import { capitalize } from 'lodash';
import moment from 'moment';
import { ReactElement, useEffect } from 'react';

interface HistoryCardProps {
	data?: any;
	downloaded?: any;
	// glbUrl?: string;
	onClick?: () => void;
	thumbnailUrl?: string;
	title?: string;
	/**	Whether to display the card in a grid view or rows */
	gridView?: boolean;
}

export default function HistoryCard({
	data,
	downloaded,
	// glbUrl = '',
	onClick,
	thumbnailUrl = defaultThumbnail?.src || defaultThumbnail,
	title = 'Untitled Project',
	gridView = false,
}: HistoryCardProps): ReactElement {
	const history = useAtomValue(userHistoryAtom);
	const inApp = useAtomValue(inAppBrowserAtom);
	const previousData = usePreviousProps(data);
	const qStatus = useAtomValue(queueStatus);
	const { id } = data;

	const qHistory = history.find((request: { id: string }) => request.id === id);
	const qIndex = qStatus?.requests.find((request: { id: string }) => request.id === id);

	const displayLoading =
		qIndex &&
		(qIndex.qIndex >= 0 ||
			qIndex.inProgress ||
			(qHistory?.status && !['failed', 'complete'].includes(qHistory.status)));

	const getMeshType = () => {
		if (data.data.meshType === 'human') {
			return 'Human';
		}
		if (data.data.meshType === 'object') {
			return 'Object';
		}
		if (data.data.meshType === 'animal') {
			return 'Animal';
		}
		return '';
	};

	useEffect(() => {
		if (data.status === 'failed' && previousData?.status !== data?.status) {
			getMyBalance();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const formatDate = (createdAt: { toDate: () => moment.MomentInput }) => {
		const now = moment();
		const createdDate = moment(createdAt?.toDate());
		if (now.diff(createdDate, 'days') > 7) {
			// createdDate.format('YYYY-MM-DD');
			// return createdDate.format('YY/MM');
			return createdDate.format('MMMM DD, YYYY');
		}
		return createdDate.fromNow();
	};

	return (
		<Grid
			item
			sx={{
				width: '100%',
				height: '100%',
			}}
			xs={!gridView ? 12 : 6}
		>
			<Card
				sx={{
					border: (theme) => `1px solid ${theme.palette.background.border}`,
					borderRadius: '12px',
					backgroundColor: 'background.default',
					width: '100%',
					minWidth: '100% !important',
					height: '100%',
					minHeight: '100% !important',
				}}
			>
				<CardActionArea
					onClick={() => {
						if (data.status !== 'failed') onClick?.();
					}}
					sx={{
						display: !gridView ? 'flex' : 'initial',
						justifyContent: !gridView ? 'flex-start' : 'initial',
						'&.Mui-disabled': { opacity: 0.5 },
						width: '100%',
						cursor: data.status === 'failed' ? 'initial' : 'pointer',
					}}
				>
					<CardMedia
						alt='Card Image'
						component={displayLoading ? 'div' : 'img'}
						image={thumbnailUrl}
						onError={(e: { currentTarget: { src: string } }) => {
							e.currentTarget.src = defaultThumbnail?.src || defaultThumbnail;
						}}
						sx={{
							bgcolor: '#4a4c55',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							objectFit: 'cover',
							position: 'relative',
							width: !gridView ? '35%' : '100%',
							height: '100%',
							minHeight: '100px',
							minWidth: !gridView ? '35%' : '100%',
							span: {
								width: '100%',
								height: '100%',
							},
							svg: {
								width: '100%',
								height: '100%',
							},
						}}
					>
						{displayLoading ? <CircularProgress /> : null}
					</CardMedia>

					<CardContent sx={{ maxWidth: '70%', padding: '0 3%' }}>
						<Typography
							aria-label={title}
							component='div'
							mb={0.5}
							noWrap
							sx={{ textOverflow: 'ellipsis' }}
							variant='body1'
						>
							{title}
						</Typography>

						<Typography color='text.disabled' variant='body2'>
							{getMeshType()}
						</Typography>

						{qIndex && qIndex.qIndex >= 0 && !qIndex.inProgress ? (
							<Typography color='text.secondary' variant='body2'>
								{qIndex.qIndex}/{qStatus?.queueLength} in queue
							</Typography>
						) : qHistory?.status && displayLoading ? (
							<Typography color='text.secondary' variant='body2'>
								{capitalize(qHistory.status)}...
							</Typography>
						) : null}

						<Typography color='text.disabled' variant='body2'>
							{formatDate(data.createdAt)}
						</Typography>

						{data.status === 'failed' && (
							<Tooltip
								title={
									<Stack direction='column' spacing={1}>
										<Typography>
											Unfortunately, this generation failed. But don&apos;t worry! Your credits
											will be returned to your account.
										</Typography>

										<Typography
											sx={{
												a: {
													color: (theme) => `${theme.palette.text.action} !important`,
												},
											}}
										>
											Please try again later or contact us for{' '}
											<LinkWrapper
												href={urls.routes.feedback}
												openNewTab={
													!inApp && !window.matchMedia('(display-mode: standalone)').matches
												}
											>
												help
											</LinkWrapper>
											.
										</Typography>

										<Typography>Asset ID: {id}</Typography>
									</Stack>
								}
							>
								<Typography
									color='error'
									sx={{
										bottom: 12,
										cursor: 'help',
										position: 'absolute',
										right: 18,
									}}
									variant='caption'
								>
									<Error />
								</Typography>
							</Tooltip>
						)}

						{downloaded && (
							<Tooltip title='Downloaded'>
								<Typography
									color='primary'
									sx={{
										position: 'absolute',
										bottom: 12,
										right: 18,
									}}
									variant='caption'
								>
									<DownloadDone />
								</Typography>
							</Tooltip>
						)}
					</CardContent>
				</CardActionArea>

				{displayLoading && (
					<LinearProgress
						value={
							data?.progress !== 100 &&
							data?.progress >= 0 &&
							!moment(data.createdAt.toDate()).isBefore(moment().subtract(1, 'h'))
								? data.progress
								: 0
						}
						variant={
							data?.progress !== 100 &&
							data?.progress >= 0 &&
							!moment(data.createdAt.toDate()).isBefore(moment().subtract(1, 'h'))
								? 'determinate'
								: 'indeterminate'
						}
					/>
				)}
			</Card>
		</Grid>
	);
}
