import GalleryCards from '@genai/components/gallery/GalleryCards';
import { defaultSearchConfig } from '@mpx-sdk/shared/configs/algolia';
import AlgoliaDisplay from '@mpx-sdk/ui/components/algolia/AlgoliaDisplay';
import { ReactElement } from 'react';

interface GalleryDisplayProps {
	pagination?: number;
}

/** A component to display search results from Algolia. */
export default function GalleryDisplay({ pagination = 0 }: GalleryDisplayProps): ReactElement {
	const searchSettings = {
		...defaultSearchConfig(),
		pagination,
		facet: ['madeIn'],
		facetFilters: ['madeIn:MPX GenAI'],
	};

	return (
		<AlgoliaDisplay
			assetDisplayJSX={<GalleryCards />}
			customSearchSettings={searchSettings}
			display={{
				searchBar: false,
				refinement: false,
			}}
			infiniteScrollTarget='gallery-display-container'
		/>
	);
}
