import firebase from '@mpx-sdk/helpers/firebase';
import { nanoid } from '@mpx-sdk/shared/utils';
import { Timestamp } from 'firebase/firestore';

class MLSession {
	id: string;

	storageRoot: string;

	createdAt: Timestamp;

	sessionId: string | MLSession;

	generatedModelsCount: number;

	constructor(id?: string) {
		const user = firebase.getCurrentUser();

		if (!user) {
			throw new Error('MLSession.constructor: User not logged in');
		}

		this.id = id || nanoid(4);
		this.storageRoot = `ml-sessions/${user.uid}/${this.id.trim()}`;
		this.createdAt = Timestamp.now();
		this.generatedModelsCount = 0;

		// Save in local storage
		localStorage.setItem('ml-session', JSON.stringify(this));
	}
}

export default MLSession;
