import { UIHelper } from '@mpx-sdk/helpers/ui';
import { GradientTypography } from '@mpx-sdk/ui/components';
import { Box, Stack, Typography } from '@mui/material';

function Slide1() {
	const isMobile = UIHelper.isMobile();

	return (
		<Box
			sx={{
				pt: {
					xs: 10,
					sm: 0,
				},
			}}
		>
			<Stack component={Typography} textAlign='left' variant={isMobile ? 'h6' : 'h4'}>
				<GradientTypography textAlign='left' variant='inherit'>
					Introducing 3D Generation
				</GradientTypography>
				<span>with Masterpiece X - Generate</span>
			</Stack>
			<Stack
				spacing={2}
				sx={{
					backgroundColor: isMobile ? 'none' : '#303030',
					width: { md: '60%', xs: '100%' },

					padding: isMobile ? 0 : 3,
					pr: isMobile ? 0 : 5,
					borderRadius: '20px',
					mt: '20px',
					pb: 5,
				}}
			>
				<Typography color='primary' fontWeight='bold' textAlign='left' variant={isMobile ? 'body1' : 'h6'}>
					This isn&apos;t your standard “text-to-whatever” AI tool.
				</Typography>

				<Stack component={Typography} spacing={2} variant='body1'>
					<p>
						We&apos;ve spent years simplifying the complexity of 3D creation, granting you the power to make
						models without the usual training or complex software.
					</p>

					<p>
						Now, we&apos;re thrilled to empower you with the ability to effortlessly create stunning 3D
						models.
					</p>
				</Stack>
			</Stack>
		</Box>
	);
}

export default Slide1;
