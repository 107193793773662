import { ArrowDropDown } from '@mui/icons-material';
import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';

interface SortingProps {
	onSortingChange: (sortingValue: string) => void;
}

export default function Sorting({ onSortingChange }: SortingProps) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [sortingValue, setSortingValue] = useState('Newest');

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setIsOpen(!isOpen);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setIsOpen(false);
	};

	const handleSortingChange = (event: React.MouseEvent<HTMLElement>) => {
		const newSortingValue = event.currentTarget.getAttribute('value') as string;
		setSortingValue(newSortingValue);
		onSortingChange(newSortingValue);
		handleClose();
	};

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<MenuItem
				onClick={handleClick}
				sx={{
					alignItems: 'center',
					border: (theme) => `1px solid ${theme.palette.background.info}`,
					borderRadius: '20px',
					cursor: 'pointer',
					display: 'flex',
					mb: 1,
					minHeight: '40px !important',
					py: 0.3,
				}}
			>
				<Stack alignItems='center' direction='row' spacing={1}>
					<Typography sx={{ color: '#ffffff' }} variant='body1'>
						Sort
					</Typography>

					<ArrowDropDown
						sx={{
							color: (theme) => theme.palette.text.primary,
							transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
							transition: 'transform 0.3s ease-in-out',
						}}
					/>
				</Stack>
			</MenuItem>

			<Menu anchorEl={anchorEl} id='Sorting-menu' onClose={handleClose} open={Boolean(anchorEl)}>
				<MenuItem onClick={handleSortingChange} selected={sortingValue === 'Newest'} value='Newest'>
					Newest
				</MenuItem>
				<MenuItem onClick={handleSortingChange} selected={sortingValue === 'Oldest'} value='Oldest'>
					Oldest
				</MenuItem>
				<MenuItem
					onClick={handleSortingChange}
					selected={sortingValue === 'Alphabetical (A-Z)'}
					value='Alphabetical (A-Z)'
				>
					Alphabetical (A-Z)
				</MenuItem>
				<MenuItem
					onClick={handleSortingChange}
					selected={sortingValue === 'Alphabetical (Z-A)'}
					value='Alphabetical (Z-A)'
				>
					Alphabetical (Z-A)
				</MenuItem>
				{/* <MenuItem
					onClick={handleSortingChange}
					selected={sortingValue === 'Status (Completed First)'}
					value='Status (Completed First)'
				>
					Status (Completed First)
				</MenuItem>
				<MenuItem
					onClick={handleSortingChange}
					selected={sortingValue === 'Status (Completed Last)'}
					value='Status (Completed Last)'
				>
					Status (Completed Last)
				</MenuItem> */}
			</Menu>
		</Box>
	);
}
