import axios from 'axios';

export interface Request {
	/** The ID of the request. */
	id: string;
	/** The queue index of the request. */
	qIndex: number;
	/** Indicates if the request is in progress. */
	inProgress: boolean;
}

export interface ApiResponse {
	/** The length of the queue. */
	queueLength: number;
	/** The number of workers in progress.  */
	workersInProgress: number;
	/** The list of requests. */
	requests: Request[];
}

export const API_URL = process.env.NEXT_PUBLIC_QUEUE_API;

/**
 * Fetches the queue status from the API.
 * @returns A promise that resolves to the API response.
 * @throws An error if the API call fails.
 */
export const getQStatus = async (
	/** An array of request IDs */
	requestIds: string[],
): Promise<ApiResponse> => {
	try {
		const requestBody = { requests: requestIds };

		const response = await axios.post(API_URL, requestBody);

		return response.data;
	} catch (error) {
		// Handle any error that occurred during the API call
		throw new Error('Failed to fetch queue status');
	}
};
