import {
	ArrowDropDown,
	CheckCircle as CheckCircleIcon,
	FilterList as FilterListIcon,
	RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, FormGroup, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import React, { useState } from 'react';

interface FilterProps {
	onFilterChange: (filterValue: string[]) => void;
}

function Filter({ onFilterChange }: FilterProps) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedValues, setSelectedValues] = useState<string[]>([]);
	const filterOptions = ['all', 'complete', 'processing', 'humans', 'animals', 'objects', 'downloads'];

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setIsOpen(!isOpen);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setIsOpen(false);
	};

	const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.name;

		let newSelectedValues: string[];

		if (value === 'all') {
			if (selectedValues.length === 6) {
				newSelectedValues = [];
			} else {
				newSelectedValues = filterOptions;
			}
		} else {
			newSelectedValues = [...selectedValues];

			if (newSelectedValues.includes(value)) {
				newSelectedValues = newSelectedValues.filter((val) => val !== value);
			} else {
				newSelectedValues.push(value);
			}
		}

		setSelectedValues(newSelectedValues);
		onFilterChange(newSelectedValues);
	};

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<MenuItem
				onClick={handleClick}
				sx={{
					alignItems: 'center',
					border: (theme) => `1px solid ${theme.palette.background.info}`,
					borderRadius: '20px',
					cursor: 'pointer',
					display: 'flex',
					mb: 1,
					minHeight: '40px !important',
					mr: 1,
					py: 0.3,
				}}
			>
				<Stack alignItems='center' direction='row' spacing={1}>
					<FilterListIcon sx={{ color: '#ffffff' }} />

					<Typography sx={{ color: '#ffffff' }} variant='body1'>
						Filter
					</Typography>

					<ArrowDropDown
						sx={{
							color: (theme) => theme.palette.text.primary,
							transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
							transition: 'transform 0.3s ease-in-out',
						}}
					/>
				</Stack>
			</MenuItem>

			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				id='filter-menu'
				onClose={handleClose}
				open={Boolean(anchorEl)}
				sx={{
					paddingTop: '16px', // Increase the top padding
					paddingBottom: '16px', // Increase the bottom padding
					marginTop: '8px', // Increase the top margin
					marginLeft: '8px', // Increase the left margin
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<FormGroup>
					{filterOptions.map((item) => (
						<FormControlLabel
							key={item}
							control={
								<Checkbox
									checked={
										item === 'all' ? selectedValues.length === 7 : selectedValues.includes(item)
									}
									checkedIcon={<CheckCircleIcon />}
									icon={<RadioButtonUncheckedIcon />}
									name={item}
									onChange={handleFilterChange}
									sx={{
										marginLeft: '10px', // Increase the left margin
									}}
								/>
							}
							label={capitalize(item)}
						/>
					))}
				</FormGroup>
			</Menu>
		</Box>
	);
}

export default Filter;
