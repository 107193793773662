import { ApiResponse, getQStatus } from '@mpx-sdk/helpers/queue';
import { queueStatus } from '@mpx-sdk/shared/atoms';
import { Box, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { isEqual } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface QueueDisplayProps {
	/** The history of requests to display */
	history?: any;
}

/** Displays the current queue status */
export default function QueueDisplay({ history }: QueueDisplayProps): ReactElement {
	const [error, setError] = useState<string | null>(null);
	const [highVolumeWarning, setHighVolumeWarning] = useState(false);
	const [qStatus, setQStatus] = useAtom(queueStatus);
	const [response, setResponse] = useState<ApiResponse | null>(null);
	const queueHighWorkloadThreshold = 100;

	function checkHighWorkload() {
		if (response?.queueLength && response.queueLength > queueHighWorkloadThreshold && !highVolumeWarning) {
			toast.warning("We're facing high volume right now. It may take longer than usual to process your request.");

			setHighVolumeWarning(true);
		}
	}

	useEffect(() => {
		// eslint-disable-next-line prefer-const
		let interval: NodeJS.Timeout;

		const fetchQStatus = async () => {
			try {
				// Grab all session IDs from the history
				const requestIds = history.map((item: { id: any }) => item.id);

				const apiResponse = await getQStatus(requestIds);

				if (!isEqual(apiResponse, qStatus)) {
					setQStatus(apiResponse);

					checkHighWorkload();
				}

				setResponse(apiResponse);
				setError(null);
			} catch (error) {
				// Handle any error that occurred during the API call
				console.error('Error retrieving queue status', error);
				clearInterval(interval);
				setError('Unable to retrieve queue status');
			}
		};

		// Initial fetch
		fetchQStatus();

		// Polling interval
		interval = setInterval(fetchQStatus, 10000); // 10 seconds

		// Cleanup interval on component unmount or when history changes
		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);

	// Render your component based on the response
	if (error) {
		return (
			<Typography color='error' mt={3} textAlign='center'>
				{error}
			</Typography>
		);
	}

	if (!response) {
		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Tooltip arrow describeChild title='Loading queue status...'>
					<CircularProgress disableShrink />
				</Tooltip>
			</Box>
		);
	}

	// Render the response data
	return (
		<Stack
			alignItems='center'
			direction='row'
			justifyContent='flex-end'
			spacing={2}
			sx={{
				padding: '1rem 0.5rem',
			}}
		>
			<Typography>Queue: {response.queueLength}</Typography>
			<Typography>Progress: {response.workersInProgress}</Typography>
		</Stack>
	);
}
