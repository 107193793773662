import { activeStepAtom } from '@genai/atoms';
import MiddleSectionTitle from '@genai/components/middle-section/MiddleSectionTitle';
import StepNames from '@genai/models/StepNames';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { genAiResponsiveViewAtom } from '@mpx-sdk/shared/atoms';
import { Box, Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/styles';
import { useSetAtom } from 'jotai';
import Image from 'next/image';
import { useForm } from 'react-final-form';

function ResultsStep() {
	const form = useForm();
	const responsiveView = UIHelper.isResponsiveView();
	const setActiveStep = useSetAtom(activeStepAtom);
	const setGenAiResponsiveViewAtom = useSetAtom(genAiResponsiveViewAtom);
	const theme = useTheme();
	const handleCreateNew = () => {
		// Reset the form by clearing the values and errors in the form fields
		form.reset();
		setActiveStep(StepNames.START);
	};

	return responsiveView ? (
		<Stack>
			{/* <Image alt='Result_Image' height={80} src='/ResultMasterpieceLogo.svg' width={80} /> */}
			{/* <Typography mt={2}>Generating your 3D Models</Typography> */}

			{/* <Box sx={{ width: '100%', mt: 2 }}>
					<LinearProgressWithLabel value={progress} />
				</Box> */}
			<br />
			{/* <Divider sx={{ mt: 2, mb: 2, width: '100%' }} /> */}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Image alt='Confetti_Icon' height={40} src='/confetti_icon.svg' width={50} />
			</Box>

			<Typography mt={2} textAlign='center'>
				Your model(s) are now generating!
			</Typography>
			<Typography mt={2} textAlign='center'>
				You &apos;ll see them on your History Panel👉
			</Typography>
			<Typography color='lightgrey' mb={2} mt={2} textAlign='center'>
				While you wait:
			</Typography>

			<ul style={{ color: 'lightgrey' }}>
				<li>
					Check out what everyone’s creating in the middle section Gallery or what model’s people are remixing
					in our{' '}
					<a
						href='https://app.masterpiecex.com/library'
						style={{
							color: '#00EAB7',
							textDecoration: 'underlined',
						}}
					>
						Community Library
					</a>
				</li>
				<br />
				<li>Listen to some music and do a happy dance with all the time you saved creating 3D models!</li>
				<br />
				<li>Share your generated models to the Community Library for others to remix!</li>
			</ul>

			<Button
				fullWidth
				onClick={handleCreateNew}
				sx={{
					background: (theme) => theme.palette.gradient.main,
					backgroundColor: 'original.main',
					borderRadius: '100px',
					fontSize: 'clamp(0.5rem, 1rem, 1rem)',
					fontWeight: '700',
					letterSpacing: '0.0025em',
					lineHeight: '32px',
					alignItems: 'center',
					mt: 2,
					'&:hover': {
						background: (theme) => theme.palette.gradient.hover,
					},
				}}
				variant='contained'
			>
				Create New
			</Button>

			{/* <Button variant='contained' color='secondary' fullWidth onClick={handleCancel}>
					Cancel
				</Button> */}
		</Stack>
	) : (
		<Stack margin={{ xs: 0.5, lg: 6 }}>
			<Stack
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					textAlign: 'center',
					mt: 0.5,
				}}
			>
				<MiddleSectionTitle type='title'>Your 3D models are now generating</MiddleSectionTitle>
				<MiddleSectionTitle type='subtitle'>
					You&apos;ll see them in your History tab below 👇
				</MiddleSectionTitle>
			</Stack>

			<MiddleSectionTitle type='heading'>While You Wait</MiddleSectionTitle>

			<Stack spacing={2}>
				<Button
					onClick={handleCreateNew}
					sx={{
						background: theme.palette.gradient.main,
						backgroundColor: 'original.main',
						borderRadius: '100px',
						fontSize: 'clamp(0.5rem, 1rem, 1rem)',
						fontWeight: '700',
						letterSpacing: '0.0025em',
						lineHeight: '32px',
						'&:hover': {
							background: theme.palette.gradient.hover,
						},
					}}
					type='submit'
					variant='contained'
				>
					Generate Another 3D Model
				</Button>

				<Button
					onClick={() => {
						setGenAiResponsiveViewAtom('gallery-display');
					}}
					sx={{
						background: theme.palette.gradient.main,
						backgroundColor: 'original.main',
						borderRadius: '100px',
						fontSize: 'clamp(0.5rem, 1rem, 1rem)',
						fontWeight: '700',
						letterSpacing: '0.0025em',
						lineHeight: '32px',
						'&:hover': {
							background: theme.palette.gradient.hover,
						},
					}}
					type='submit'
					variant='contained'
				>
					View Other Generated 3D Models
				</Button>

				<Button
					onClick={() => {
						setGenAiResponsiveViewAtom('history-display');
					}}
					sx={{
						background: theme.palette.gradient.main,
						backgroundColor: 'original.main',
						borderRadius: '100px',
						fontSize: 'clamp(0.5rem, 1rem, 1rem)',
						fontWeight: '700',
						letterSpacing: '0.0025em',
						lineHeight: '32px',
						'&:hover': {
							background: theme.palette.gradient.hover,
						},
					}}
					type='submit'
					variant='contained'
				>
					View Progress and History
				</Button>
			</Stack>
		</Stack>
	);
}

export default ResultsStep;
