import ChipsList from '@genai/components/left-sidebar/TooltipChips';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import FormField, { defaultMaxLength } from '@mpx-sdk/ui/components/core/form/FormField';
import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import { Button, Collapse, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-final-form';

export default function PaintStep() {
	const form = useForm();
	const responsiveView = UIHelper.isResponsiveView();
	const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
	const autofillButton = (text: string, fieldState: string) => {
		let currentValue = form.getFieldState(fieldState)?.value || '';

		if (currentValue !== '') {
			currentValue += ', ';
		}

		const newValue = `${currentValue}${text}`;
		if (newValue?.length <= defaultMaxLength) {
			form.change(fieldState, newValue);
		}
	};

	const autofillOptions = [
		'hyperrealistic',
		'pop-art',
		'modernist',
		'vintage',
		'art nouveau',
		'psychedelic',
		'cartoon',
		'underwater steampunk',
	];

	const autofillOptions2 = [
		'4K',
		'Artstation',
		'Diffuse Lighting',
		'Highly Detailed',
		'Beautiful',
		'Vibrant',
		'Reflections',
		'Elegant',
		'Intricate',
	];

	const autofillOptions3 = [
		'Ugly',
		'Blurry',
		'Messy',
		'Splotchy',
		'Low-resolution',
		'Inconsistent',
		'Low-poly',
		'Basic',
		'Uniform Lighting',
	];

	return (
		<>
			<Typography variant='h6'>Paint Details</Typography>

			<Stack spacing={2}>
				<Stack pt={1} spacing={1}>
					<FormField
						fullWidth
						infoTooltip={{
							paragraphs: [
								<>
									<b>Ask yourself: </b>
									<ul>
										<li> How do you want your model to look like?</li>{' '}
										<li> What are the colors? </li> <li>What is the style of the model?</li>
									</ul>
								</>,
							],
							title: 'Paint Details',
						}}
						interactive
						multiline
						name='promptPos'
						placeholder="Create your model's textures. The more descriptive you are, the better your results will be."
						rows={4}
						title={`Add more detail about how the ${form.getFieldState('meshType')?.value} looks`}
						tooltip={
							responsiveView
								? {
										title: 'Paint Details',
										content: [
											`The Paint stage creates your model's texture.`,

											<ChipsList
												key='chipList2'
												chips={autofillOptions}
												onClick={(option) => autofillButton(option, 'promptPos')}
											/>,

											'Improve your prompt by using keywords like:',
											<ChipsList
												key='chipList3'
												chips={autofillOptions2}
												onClick={(option) => autofillButton(option, 'promptPos')}
											/>,
											<div
												key='limitations'
												style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
											>
												{/* eslint-disable-next-line @next/next/no-img-element */}
												<img
													alt='Limitations-Alert'
													height='20px'
													src='/alert-triangle.svg'
													width='10%'
												/>
												<Typography>Limitations for humanoid models:</Typography>
											</div>,

											<ul key=''>
												<li>No accessories</li>
												<li>Limited hair styles (no spikes, mullets)</li>
												<li>Limited footwear (no specifics outside of “shoes/boots”)</li>
												<li>
													No layers or specific garments of clothing (robes, bomber jackets)
												</li>
											</ul>,
										],
								  }
								: undefined
						}
						variant='outlined'
					/>

					{!responsiveView && (
						<ChipsList
							key='chipListResponsive1'
							chips={[...autofillOptions, ...autofillOptions2]}
							onClick={(option) => autofillButton(option, 'promptPos')}
						/>
					)}
				</Stack>
				<Button
					onClick={() => {
						setIsAdvancedOpen((isOpen) => !isOpen);
					}}
				>
					Advanced Settings {!isAdvancedOpen ? <ChevronRight /> : <KeyboardArrowDown />}
				</Button>
				<Collapse in={isAdvancedOpen}>
					<Stack spacing={1}>
						<FormField
							fullWidth
							interactive
							multiline
							name='promptNeg'
							placeholder='This field is optional. But, filling it out will yield better results.'
							rows={4}
							title={`What does your ${form.getFieldState('meshType')?.value} not look like?`}
							tooltip={
								responsiveView
									? {
											title: 'Paint Details',
											content: [
												`What you DON'T want your model to look like`,

												<ChipsList
													key='chipList4'
													chips={autofillOptions3}
													onClick={(option) => autofillButton(option, 'promptNeg')}
												/>,
												`You can also include styles you DON'T want:`,
												<ChipsList
													key='chipList5'
													chips={autofillOptions}
													onClick={(option) => autofillButton(option, 'promptNeg')}
												/>,
											],
									  }
									: undefined
							}
							variant='outlined'
						/>

						{!responsiveView && (
							<ChipsList
								key='chipListResponsive3'
								chips={autofillOptions3}
								onClick={(option) => autofillButton(option, 'promptNeg')}
							/>
						)}
					</Stack>
				</Collapse>
			</Stack>
		</>
	);
}
