import ChipsList from '@genai/components/left-sidebar/TooltipChips';
import useSource from '@genai/hooks/useSource';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import FormField from '@mpx-sdk/ui/components/core/form/FormField';
import IconTooltip from '@mpx-sdk/ui/components/tooltips/IconTooltip';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { Field, useForm } from 'react-final-form';

const PreferenceStyles = {
	textAlign: 'center',
	margin: 'auto',
	span: {
		fontWeight: 'bold',
		fontSize: '0.8rem',
	},
	fontSize: '1rem',
};

const ResultPreference = [
	{
		value: 0,
		label: (
			<Typography sx={PreferenceStyles} variant='caption'>
				<span>More</span> <br /> Precise
			</Typography>
		),
	},
	{
		value: 2,
		label: (
			<Typography sx={PreferenceStyles} variant='caption'>
				<span>More</span> <br /> Balanced{' '}
			</Typography>
		),
	},
	{
		value: 4,
		label: (
			<Typography sx={PreferenceStyles} variant='caption'>
				<span>More</span> <br /> Creative
			</Typography>
		),
	},
];

export default function ShapeStep() {
	const source = useSource();
	const form = useForm();
	const responsiveView = UIHelper.isResponsiveView();

	const handleAutofillClick = (text: string) => {
		form.change('looksLikePrompt', `${text}`);
	};

	const getMeshType = () => {
		const meshType = form.getFieldState('meshType')?.value;
		if (meshType === 'human') {
			return 'human';
		}
		if (meshType === 'animal') {
			return 'animal';
		}
		return 'object';
	};

	const autofillOptions: Record<string, string[]> = {
		animal: [
			'Bird',
			'Cat',
			'Dog',
			'Male Lion',
			'Frog',
			'Horse',
			'Zebra',
			'Bear',
			'Lizard',
			'Chicken',
			'Whale Shark',
		],
		human: [
			'Viking',
			'Pirate',
			'Policeman',
			'Astronaut',
			'Soldier',
			'Princess',
			'Athletic Male Superhero',
			'Superhero',
			'Strong Female Warrior',
			'Wizard',
		],
		object: [
			'Apple',
			'Axe',
			'Basketball',
			'Book',
			'Treasure Chest',
			'Bread',
			'Lantern',
			'Bottle of Wine',
			'Wooden Barrel',
			'Knights Helmet',
			'Magic Playing Cards',
		],
	};

	return (
		<>
			<Typography variant='h6'>Shape Details</Typography>
			{source === 'library' && (
				<FormGroup>
					<FormControlLabel control={<Checkbox defaultChecked />} label='Keep Shape' />
				</FormGroup>
			)}

			<Stack spacing={1}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Typography>Concisely describe the {getMeshType()}</Typography>

					{/* Add the IconTooltip component here */}
					{getMeshType() === 'human' && (
						<IconTooltip
							key='tooltip-shape-step-word-count'
							paragraphs={[
								"Human models won't generate well with accessories included in the prompt.",
								"You can optionally add in your human model's gender for a more specific result.",
							]}
							title='About this Step'
						/>
					)}
				</Box>

				<FormField
					fullWidth
					hideCharacterCount
					interactive
					maxWords={5}
					multiline
					name='looksLikePrompt'
					placeholder='Describe what you want the AI to generate. Be concise and specific.'
					rows={4}
					title=''
					tooltip={
						responsiveView
							? {
									title: 'Object Shape Details',
									content: [
										"The Shape stage creates your 3D model's mesh.",

										<ChipsList
											key='chipslist1'
											chips={autofillOptions[getMeshType()]}
											onClick={(option) => {
												handleAutofillClick(option);
											}}
										/>,

										<div
											key='limitations'
											style={{
												display: 'flex',
												alignItems: 'center',
												marginTop: '20px',
											}}
										>
											<Image
												alt='Limitations-Alert'
												height={50}
												src='/alert-triangle.svg'
												width={20}
											/>
											&nbsp;
											<Typography fontWeight='bold'>Limitations</Typography>
										</div>,

										<ul key=''>
											<li>English only</li>
											<li>No logos</li>
											<li>Buildings and landscapes may not generate well</li>
										</ul>,
									],
							  }
							: undefined
					}
					variant='outlined'
				/>

				{!responsiveView && (
					<ChipsList
						key='chipslist1-sub'
						chips={autofillOptions[getMeshType()]}
						onClick={(option) => handleAutofillClick(option)}
					/>
				)}

				{/* {getMeshType() && autofillOptions[getMeshType()] && (
					<Stack direction='column' spacing={1}>
						<Typography variant='caption'>Examples:</Typography>

						<Box>
							{autofillOptions[getMeshType()].map((option) => (
								<ButtonLink
									key={option}
									onClick={() => handleAutofillClick(option)}
									sx={{
										margin: 'auto 0.5rem',
									}}
									variant='caption'
								>
									{option}
								</ButtonLink>
							))}
						</Box>
					</Stack>
				)} */}

				<Box>
					<Typography component='div' id='discrete-slider' mb={3} mt={3}>
						Shape Style (Beta)
						<IconTooltip
							key='tooltip-shape-step'
							paragraphs={[
								<Typography key='shape-style-tooltip-1'>
									<b>More Precise</b> creates more realistic and accurate shapes, but is limited in
									the types of shapes it produces.
								</Typography>,
								<Typography key='shape-style-tooltip-2'>
									<b>More Creative</b> has flexibility to produce a more specific shape, but the
									shapes might be lower quality with more unwanted characteristics.
								</Typography>,
							]}
							title='Results Preference'
						/>
					</Typography>
					<Field
						name='meshVariability'
						render={({ input }) => (
							<Stack
								direction='row'
								justifyContent='space-between'
								sx={{
									backgroundColor: 'background.offBlack',
									padding: 1,
									border: (theme) => `1px solid ${theme.palette.background.border}`,
									borderRadius: '5px',
								}}
							>
								{/* For each value in ResultPreference, create a button */}
								{ResultPreference.map((value) => (
									<Button
										key={value.value}
										onClick={() => input.onChange(value.value)}
										sx={{
											backgroundColor: input.value === value.value ? 'primary.main' : 'initial',
											color: input.value === value.value ? 'primary.contrastText' : 'white',
											width: '100%',
											height: '100%',
											borderRadius: '5px',
											fontSize: '0.8rem',
											fontWeight: 'bold',
											padding: '0.5rem',
											transition: 'all 0.2s ease-in-out',
											'&:hover': {
												backgroundColor:
													input.value === value.value ? 'primary.light' : 'background.paper',
												color: input.value === value.value ? 'primary.contrastText' : 'white',
											},
										}}
										variant='contained'
									>
										{value.label}
									</Button>
								))}
							</Stack>
						)}
						type='range'
					/>
				</Box>
			</Stack>
		</>
	);
}
