import GalleryDisplay from '@genai/components/gallery/GalleryDisplay';
import GalleryHeader from '@genai/components/gallery/GalleryHeader';
import { Stack } from '@mui/material';

export default function Gallery() {
	return (
		<Stack
			id='gallery-display-container'
			pt={8}
			sx={{
				background: `linear-gradient(135deg, rgba(255,155,83,0.2) 1%, rgba(255,155,83,0) 15%, rgba(60,198,164,0) 85%, rgba(60,198,164,0.2) 99%), url('/images/background/stars.svg')`,
				flex: 1,
				padding: '0.5%',
				scrollBehavior: 'smooth',
				transform: 'translateZ(0)',
				width: '100%',
			}}
			textAlign='center'
		>
			<GalleryHeader />

			<GalleryDisplay />
		</Stack>
	);
}
