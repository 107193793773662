import { UIHelper } from '@mpx-sdk/helpers/ui';
import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import { isEmpty } from 'lodash';
import { ReactElement } from 'react';

interface ChipsListProps {
	chips: string[];
	onClick?: (chip: string) => void;
}

export default function ChipsList({ chips, onClick }: ChipsListProps): ReactElement | null {
	const responsiveView = UIHelper.isResponsiveView();

	const handleChipClick = (chip: string) => {
		onClick?.(chip);
	};

	return !isEmpty(chips) ? (
		<Box>
			{chips.map((chip) => (
				<Chip
					key={chip}
					color={responsiveView ? 'secondary' : 'default'}
					label={chip}
					onClick={() => {
						handleChipClick(chip);
					}}
					sx={{ padding: '8px', margin: '5px', borderRadius: '6px' }}
					variant='filled'
				/>
			))}
		</Box>
	) : null;
}
