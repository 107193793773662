import MLSession from '@genai/models/MLSession';
import StepNames from '@genai/models/StepNames';
import { atom, getDefaultStore } from 'jotai';

export const store = getDefaultStore();

export const sessionAtom = atom<MLSession | null>(null);

export const resetSessionAtom = atom(null, (_get, set) => {
	set(sessionAtom, new MLSession());
});

export const activeStepAtom = atom<StepNames>(StepNames.START);
