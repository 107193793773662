import { UIHelper } from '@mpx-sdk/helpers/ui';
import { Box, Stack, Typography } from '@mui/material';

export default function GalleryHeader() {
	const responsiveView = UIHelper.isResponsiveView();

	return (
		<Stack direction='column'>
			{!responsiveView ? (
				<Box
					sx={{
						paddingTop: '1rem',
						'.MuiTypography-root': {
							fontSize: 'clamp(1rem, 1.75rem, 2.5rem) !important',
						},
						textAlign: 'center',
						fontWeight: 'bold',
						animation: 'moveGradientRightToLeft 10s linear infinite',
						backgroundSize: '200% 100%',
						color: 'transparent',
					}}
				>
					<Typography
						fontWeight='bold'
						sx={{
							color: 'text.primary',
						}}
						textAlign='center'
						variant='h2'
					>
						Made Using
					</Typography>
					<Typography
						sx={{
							textAlign: 'center',
							fontWeight: 'bold',
							backgroundImage: 'linear-gradient(91.02deg, #00EAB7 10.41%, #FF9B53 49.23%, #FF268E 89.7%)',
							WebkitBackgroundClip: 'text',
							color: 'transparent',
						}}
						variant='h2'
					>
						Masterpiece X - Generate
					</Typography>
				</Box>
			) : (
				<>
					<Box>
						<Typography fontWeight='bold' textAlign='center' variant='h2'>
							Get Ready to
						</Typography>
						<Typography
							sx={{
								textAlign: 'center',
								fontWeight: 'bold',
								backgroundImage:
									'linear-gradient(91.02deg, #00EAB7 10.41%, #FF9B53 49.23%, #FF268E 89.7%)',
								WebkitBackgroundClip: 'text',
								color: 'transparent',
							}}
							variant='h2'
						>
							Generate with AI
						</Typography>
					</Box>

					<Typography
						maxWidth='90%'
						mx='auto'
						pt={2}
						sx={{
							fontSize: '1.125rem',
							textAlign: 'center',
							fontStyle: 'normal',
							fontWeight: '500',
							lineHeight: '33.5px',
							letterSpacing: '-0.27px',
						}}
						textAlign='center'
						variant='h6'
					>
						Creating 3D used to be very hard. Not anymore!🚀 <br /> Browse this{' '}
						<b style={{ color: '#0EEBBB' }}>featured list</b> of models generated by our AI and get inspired
						to create your own.
					</Typography>
				</>
			)}
		</Stack>
	);
}
