import IconTooltip from '@mpx-sdk/ui/components/tooltips/IconTooltip';
import {
	AddCircleOutline as AddCircleOutlineIcon,
	RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@mui/icons-material';
import {
	Box,
	Card,
	CardActionArea,
	CardMedia,
	Grid,
	IconButton,
	RadioGroup,
	Slider,
	Stack,
	Typography,
} from '@mui/material';
import { ReactElement } from 'react';
import { Field, useForm } from 'react-final-form';

export default function StartStep(): ReactElement {
	const form = useForm();

	const modelTypeOptions = [
		{ label: 'Object', value: 'object', image: '/object.png' },
		{ label: 'Animal', value: 'animal', image: '/animal.png' },
		{ label: 'Human \n(Beta) ', value: 'human', image: '/humanoid.png' },
	];

	const handleModelTypeChange = (value: string) => {
		form.change('meshType', value);
	};

	return (
		<Stack direction='column' spacing={2}>
			{/* <SourceSelectionTabs /> */}

			<Typography
				fontWeight='bold'
				sx={{
					paddingLeft: 'min(6px, 1%)',
				}}
			>
				Choose a model type
				<IconTooltip
					alertIconPara={[
						<span key='model-type-tooltip-alert' style={{ marginTop: '2px' }}>
							<ul>
								<li>
									It&apos;s not for creating uncommon or obscure objects such as a “Gorilla without
									legs”.
								</li>
								<li>It may take multiple generations to get your desired result.</li>
							</ul>
						</span>,
					]}
					alertIconText={[
						<span key='model-type-tooltip-limitations' style={{ fontWeight: 'bold' }}>
							Limitations
						</span>,
					]}
					//	images={['/Start_Group.svg']}
					paragraphs={[
						<span key='model-type-tooltip-capabilities' style={{ fontWeight: 'bold', fontSize: '17px' }}>
							Capabilities
						</span>,
						<ul key='model-type-tooltip-capabilities-list'>
							<li>Create humans, animals, and objects 3D models.</li>
							<li>
								Edit the generated models in VR using the{' '}
								<a
									href='https://masterpiecestudio.com/'
									style={{
										color: '#0EEBBB',
										textDecoration: 'underline',
										fontWeight: 'bold',
									}}
								>
									Masterpiece X app on MetaQuest.
								</a>
							</li>
							<li>Add animations to human models only.</li>
							<li>Export to .GLB format.</li>
						</ul>,
					]}
					showAlertIcon // Show the Alert button
					title='Model Type'
				/>
			</Typography>

			<Field
				name='meshType'
				render={({ input }) => (
					<Grid
						component={RadioGroup}
						{...input}
						alignItems='stretch'
						container
						sx={{
							'& .MuiGrid-item': {
								paddingLeft: 'min(6px, 1%)',
								paddingRight: 'min(6px, 1%)',
							},
						}}
					>
						{modelTypeOptions.map(({ label, value, image }) => (
							<Grid key={label} item xs={4}>
								<Box
									sx={{
										height: '100%',
										display: 'flex',
									}}
									textAlign='center'
								>
									<Stack>
										<Card
											component={Stack}
											sx={{
												transition: 'all 0.1s ease-in-out',
												flexGrow: 1,
												background: (theme) =>
													input.value === value ? theme.palette.gradient.main : '',
												padding: 0.25,
											}}
										>
											<CardActionArea
												onClick={() => handleModelTypeChange(value)}
												sx={{
													backgroundColor: 'background.cards',
													pb: 1,
													height: '100%',
												}}
											>
												<CardMedia
													alt='Card Image'
													component='img'
													image={image}
													sx={{
														padding: 1.5,
														height: 'auto',
														maxWidth: '100%',
														objectFit: 'cover',
													}}
												/>

												<Typography textAlign='center' variant='body2'>
													{label}
												</Typography>
											</CardActionArea>
										</Card>
									</Stack>
								</Box>
							</Grid>
						))}
					</Grid>
				)}
			/>

			<Box>
				<Typography
					fontWeight='bold'
					sx={{
						paddingLeft: 'min(6px, 1%)',
					}}
				>
					How many models do you want?
					<IconTooltip
						alertIconText={[
							'The queue at the bottom right shows how many models are processing in our app',
							'	A longer queue means your model will take more time to generate',
						]}
						paragraphs={[
							'Choose the amount of models you want the AI to generate. ',
							'You can continue to generate more models after generating and waiting for your models to process.',
						]}
						showAlertIcon
						title='Model Amount'
					/>
				</Typography>
			</Box>

			<Field
				name='modelsToGenerate'
				render={({ input }) => (
					<Stack alignItems='center' direction='row' justifyContent='center' spacing={1}>
						<IconButton
							onClick={() => {
								if (input.value > 1) {
									input.onChange(input.value - 1);
								}
							}}
							style={{ margin: '15px' }}
						>
							<RemoveCircleOutlineIcon />
						</IconButton>

						<Slider
							aria-label='modelToGenerate'
							max={4}
							min={1}
							onChange={(_e, value) => {
								input.onChange(value); // Update the value in the form
							}}
							step={1}
							sx={{
								width: '90%',
								'.MuiSlider-valueLabel': {
									borderRadius: '8px',
									backgroundColor: 'step.completed',
								},
							}}
							value={input.value} // Set the value from the form
							valueLabelDisplay='on'
						/>

						<IconButton
							onClick={() => {
								if (input.value < 4) {
									input.onChange(input.value + 1);
								}
							}}
							style={{ margin: '15px' }}
						>
							<AddCircleOutlineIcon />
						</IconButton>
					</Stack>
				)}
				type='range'
			/>
		</Stack>
	);
}
