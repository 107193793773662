import { Typography } from '@mui/material';
import React from 'react';

export default function MiddleSectionTitle({
	children,
	type,
}: {
	children: React.ReactNode;
	type: 'subtitle' | 'title' | 'heading' | 'subtitle2' | 'CardBody';
}) {
	let titleProps: any;

	if (type === 'title') {
		titleProps = {
			variant: 'h6',
			mt: { md: 2, lg: 6.5 },
			textAlign: 'center',
			fontWeight: 'bold',
		};
	} else if (type === 'subtitle') {
		titleProps = { mb: 1, mt: 0, textAlign: 'center', color: 'white' };
	} else if (type === 'subtitle2') {
		titleProps = {
			mt: 0,
			color: 'white',
			textAlign: 'center',
			fontSize: '1.1rem',
		};
	} else if (type === 'CardBody') {
		titleProps = {
			color: 'textSecondary',
			textAlign: 'center',
			variant: 'body2',
		};
	} else {
		titleProps = {
			mb: 2,
			mt: 2,
			textAlign: 'center',
			color: 'primary',
			fontWeight: 'bold',
		};
	}

	return (
		<Typography color='primary' {...titleProps}>
			{children}
		</Typography>
	);
}
